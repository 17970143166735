import React from 'react';
import { Redirect, Router } from '@reach/router';
import Home from '../components/Home';

/* const IndexPage = () => <Redirect to="/home/" when />; */

const IndexPage = (props) => (
  <Router>
    <Home {...props} default path="/" />
    <Redirect from="/" noThrow to="/home" />
  </Router>
);

export default IndexPage;
